import Vue from 'vue'
import App from './App.vue'
import "./assets/css/app.css";
import Web3ModalOptions from "@/web3modal/options";
import web3modalMixin from "@/web3modal/web3modalMixin";
import VModal from 'vue-js-modal';

 import VueMobile from 'vue-mobile-detection';
Vue.use(VueMobile)
window.axios=require('axios');
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
axios.defaults.baseURL=process.env.VUE_APP_SERVER_BASE_URL
Vue.use(VModal);
Vue.config.productionTip = false

new Vue({
  mixins:[web3modalMixin],
  Web3ModalOptions,
  render: h => h(App)
}).$mount('#app')
