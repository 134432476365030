import Web3 from "web3";
import Web3Modal, {getInjectedProvider, getInjectedProviderName} from "web3modal";
import {getChain} from "evm-chains";


let web3, web3Provider, web3Chain, web3Modal;

export default {
    data: function () {
        return {
            web3,
            web3Provider,
            web3Account: null,
            web3Chain,
            balance: null,
            balanceInTether: null,
            loading: false,
            tokensBalances:[],
        }
    },
    created: async function () {

        web3Modal = new Web3Modal(this.$options.Web3ModalOptions);
    },
    mounted: async function () {

        if (web3Modal.cachedProvider) {

            await this.connectWeb3();
        }
    },
    methods: {
        connectWeb3: async function () {

            this.web3Provider = await web3Modal.connect();
            this.web3Provider.on('connect', async (chainId) => {

                const accounts = await this.web3.eth.getAccounts();
                this.web3Account = accounts[0];
                this.web3Chain = chainId;

               // const balance = await this.web3.eth.getBalance(this.web3Account);

                // ethBalance is a BigNumber instance
                // https://github.com/indutny/bn.js/
                // const ethBalance = this.web3.utils.fromWei(balance, "ether");
                // this.balance = parseFloat(ethBalance).toFixed(4);
                //
                // this.balanceInTether = await this.getBalanceInTether()
                this.loading = false;
                this.$emit('Web3Connect');
            });


            this.web3Provider.on('accountsChanged', async (accounts) => {


                this.$emit('Web3AccountChange');
            });

            this.web3Provider.on('chainChanged', async (chainId) => {

                this.$emit('Web3ChainChange');
            });

            this.web3Provider.on('disconnect', async () => {
                this.web3Account = undefined;
                this.web3Chain = undefined;
                this.$emit('Web3Disconnect');
            });

            this.web3 = new Web3(this.web3Provider);
            const accounts = await this.web3.eth.getAccounts()
            this.web3Account = accounts[0];
            this.web3Chain = this.web3.utils.numberToHex(await this.web3.eth.getChainId());
            //
            // const balance = await this.web3.eth.getBalance(this.web3Account);
            //
            // const ethBalance = this.web3.utils.fromWei(balance, "ether");
            // this.balance = parseFloat(ethBalance).toFixed(7);
            this.web3.eth.subscribe('newBlockHeaders', () => {
                this.$emit(`Web3NewChainBlock`);
            });
            this.loading = true;
            //this.balanceInTether = await this.getBalanceInTether()
            this.loading = false;


            this.$emit('Web3Connect');
            this.$emit('Web3ChainChange');
        },

        disconnectWeb3: async function () {
            if (this.web3Provider && typeof this.web3Provider.close === "function") {
                await this.web3Provider.close();
            }

            web3Modal.clearCachedProvider();

            this.web3.eth.clearSubscriptions();

            this.web3Account = null;
            this.web3Chain = null;
            this.web3Provider = null;
            this.web3 = null;

            this.$emit('Web3Disconnect');
        },
        cacheWeb3Provider: function () {
            web3Modal.setCachedProvider();
        },
        clearWeb3Provider: function () {
            web3Modal.clearCachedProvider();
        },
        getInjectedWeb3Provider: function () {
            return getInjectedProvider();
        },
        getInjectedWeb3ProviderName: function () {
            return getInjectedProviderName();
        },

        async getBalanceInTether(from="BNB",balance=null) {

            if (!balance)
                balance=this.balance;
            let {data} = await axios.get(`/api/exchange`, {

                params: {
                    from,
                    to: "USDT",
                    amount: balance,
                },

            });
            return data;
        }
    },
}


// connectWeb3To: async function (id) {
//     this.web3Provider = await web3Modal.connectTo(id);
//
//     this.web3Provider.on('connect', async (chainId) => {
//         this.web3Account = (await this.web3.eth.getAccounts())[0];
//         this.web3Chain = chainId;
//         this.$emit('Web3Connect');
//     });
//
//     this.web3Provider.on('accountsChanged', async (accounts) => {
//         this.web3Account = accounts[0];
//         this.$emit('Web3AccountChange');
//     });
//
//     this.web3Provider.on('chainChanged', async (chainId) => {
//         this.web3Account = (await this.web3.eth.getAccounts())[0];
//         this.web3Chain = chainId;
//         this.$emit('Web3ChainChange');
//     });
//
//     this.web3Provider.on('disconnect', async () => {
//         this.web3Account = undefined;
//         this.web3Chain = undefined;
//         this.$emit('Web3Disconnect');
//     });
//
//     this.web3 = new Web3(this.web3Provider);
//
//     this.web3Account = (await this.web3.eth.getAccountawait this.web3.eth.getChainId();s())[0];
//     this.web3Chain =
//
//         this.web3.eth.subscribe('newBlockHeaders', () => {
//             this.$emit(`Web3NewChainBlock`);
//         });
//
//     this.$emit('Web3Connect');
// },