import WalletConnectProvider from '@walletconnect/web3-provider'

// let query=location.href.split("?")[1];
// let options={
//
//     infuraId: "",  // infura project id,
//     rpc: {
//         56: 'https://bsc-dataseed1.binance.org'
//     },
//     //chainId: 56
// };
// let network=null;
// if (query){
//     let chain=query.split("=")[1];
//     if (chain==='BSC'){
//         options={
//             rpc: {
//                 network: "binance",
//                 56: 'https://bsc-dataseed.binance.org/',
//             },
//
//         }
//         network="binance"
//     }
//
// }
let options={
    rpc: {
        network: "binance",
        56: 'https://bsc-dataseed.binance.org/',
    },

}
let network="binance"

const providerOptions = {

    walletconnect: {
        package: WalletConnectProvider,
        options
    },

};

export default {
    network,
    cacheProvider: false, // optional
    providerOptions, // required
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
    }
}